<template>
  <v-input
    ref="input"
    :value="value"
    :rules="rules"
    :label="label"
    @input="$emit('input', $event)"
  >
    <template v-slot:prepend>
      <div class="prepend-container">
        <slot name="prepend"></slot>
      </div>
    </template>
    <v-col :cols="8" :sm="8" :md="8">
    <!--<v-row align="center" class="ml-2" justify="start" no-gutters>-->
      <v-row align="center" justify="start" no-gutters>
        <v-col cols="auto" v-for="index in 4" :key="index" class="mr-1">
          <v-text-field
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            :value="digits[index - 1] || ''"
            @input="updateDigit(index - 1, $event)"
            single-line
            dense
            hide-details
            outlined
            style="width: 28px; height: 32px;"
            class="digit-input"
            maxlength="1"
          />
        </v-col>
      </v-row>
    </v-col>
    <template v-slot:append>
      <v-icon class="append-icon" @click="handleAppendOuterClick">{{ appendOuterIcon }}</v-icon>
    </template>
  </v-input>
</template>

<style scoped>
.v-label {
  font-size: 0.8em;
}
.digit-input >>> input {
  padding: 0;
  text-align: center;
}
.digit-input >>> .v-input__slot {
    padding: 0 !important;
}
.prepend-container {
  margin-top: 0.75em;
}
.append-icon {
  margin-top: 0.75em;
}
</style>

<script>
export default {
  name: 'FourDigitInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
  },
  computed: {
    digits() {
      return this.value.split('').map(char => char);
    },
  },
  methods: {
    updateDigit(index, digit) {
      const newDigits = [...this.digits];
      newDigits[index] = digit.replace(/[^0-9]/g, '').slice(0, 1);
      this.digits = newDigits;
      this.$emit('input', newDigits.join(''));
    },
    handleAppendOuterClick() {
      this.$emit('click-append-outer');
    },
    validate() {
      return this.$refs.input.validate();
    },
  },
};
</script>