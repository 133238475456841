import $vuetify from 'vuetify/src/locale/en.ts'

export default {
	$vuetify,
	"app": {
		"manage": "Manage",
		"new_user_setup": "New User Setup",
		"access_manager": "Access Manager",
		"primary_key_manager": "Primary Key Manager",
		"your_pk_must_be_blank": "Your PK must be blank (so the server will give you a new one) or end with hyphen and a number (g.g., \"-0\")",
		"to_manage_your_pk": "To manage your PK, provide its PIN",
		"anonymous_activation": "Anonymous Activation",
		"primary_key_management": "Primary Key Management",
		"primary_key_tqrtoken_services": "Primary Key accesses TQRToken services",
		"primary_key_data_on_device": "This is your Primary Key for all your data on this device:",
		"to_secure_your_primary_key": "To secure your primary key in case of mishap, copy the key to the {b_notes} section of your own contact card in the Contact app on your device.",
		"notes": "Notes",
		"to_share_data_between_devices": "To share data between devices, add/select its Primary key here:",
		"to_enable_multiple_devices": "To enable multiple devices, paste another PK",
		"we_suggest_a_pin": "We suggest a PIN",
		"to_secure_your_pk": "Enter a 4-6 digit PIN",
		"pin_changed_to": "PIN changed to:",
		"pin_removed": "PIN removed",
		"security_pin": "Security PIN",
		"use_only_digits": "Use only digits (and 4 = 6 of them)",
		"welcome": "Welcome",		
		"no_registration": "No Registration",
		"no_app_download": "No App Download"
	},
	"newuser": {
		"anonymous_activation": "Anonymous Activation",
		"anywhere_anyone_anytime": "Anyone, Anywhere, Anytime",
		"no_app": "No App",
		"no_tracking": "No Tracking",
		"your_tensor_profile": "Personal Profile",
		"your_tensor_org_profile": "Org Profile",
	},
	"confirmdialog": {
		"cancel": "Cancel",
		"ok": "OK"
	},
	"prompt": {
		"done": "Done",
		"cancel": "Cancel"
	},
	"aegis2": {
		"positive_number_only": "Positive number only.",
		"required": "Required.",
		"add": "Add",
		"edit": "Edit",
		"a_connection": " a Connection",
		"new_campaign": "New Campaign",
		"new_reward": "New Reward",
		"root_connection": "Root Connection",
		"your_base_of_operations": "Your base of operations",
		"typically_this_is_your_brand": "Typically, this is your brand name",
		"branch_connection": "Branch connection",
		"delimit_organization_parts_with": "Delimit organization parts with @",
		"minimum_tokens": "Minimum Tokens",
		"connections": "Connections",
		"add_connection": "Add Connection",
		"register_account": "Register Account",
		"reward": "Reward",
		"campaign": "Campaign",
		"venue": "Venue",
		"type": "Type",
		"use_only_at_to_separate": "use only @ to separate name values",
		"correct_your_data": "Correct your data before you save it.",
		"register": "Register"
	},
	"rewards": {
		"earn_tokens": "Earn Tokens",
		"consumer_scans_qr_to_earn": "Consumer scans this QR code to {b_earn} tokens.",
		"earn": "earn",
		"qr_earn_url": "QR's Earn URL",
		"goes_to_the_same_place": "Goes to the same place as the QR code",
		"copy_earn_url": "Copy Earn URL",
		"rewards_view": "Rewards View",
		"consumer_scans_qr_to_burn": "Consumer scans this QR code to {b_burn} tokens.",
		"burn": "redeem",
		"qr_burn_url": "QR's Redeem URL",
		"copy_burn_url": "Copy Redeem URL",
		"earn_url": "Earn URL",
		"redirect_consumer_after_earning_tokens": "Redirect Consumer after earning tokens",
		"burn_url": "Redeem URL",
		"redirect_consumer_after_burning_tokens": "Redirect Consumer after redeeming tokens",
		"invalid_url_keep_adding": "Invalid url. Keep adding (or check against) required data.",
		"edit_reward_html": "Edit Reward HTML",
		"reward_view_html": "Reward View HTML",
		"enter_the_reward_menu_content": "Enter the Reward menu content in HTML.",
		"cancel": "Cancel",
		"save": "Save",
		"copied": "Copied",
		"thanks": "Thanks"
	},
	"campaigns": {
		"campaign_events": "Campaign Events",
		"add_campaign": "ADD CAMPAIGN",
		"add_a_new_campaign": "Add a new Campaign or add edited data to selected Campaign",	
		"welcome_to_tqr": "Welcome to TQR",
		"to_get_started": "To get started:",
		"click_add_campaign_button_above": "Click the {b_add_campaign} button above. You will see your Campaign appear.",
		"click_the_campaign_label": "Click the {b_campaign} label or the {b_add_event} button next to your new Campaign.",
		"campaign": "Campaign",
		"add_event": "Add Event",
		"fill_in_the_dialog": "Fill in the dialog and hit the {b_ok} button.",
		"ok": "OK",
		"to_see_your_events": "To see your Events, click on a Campaign label. If you need to edit an Event, click the {b_edit_event} on the Event label.",
		"edit_event": "Edit Event",
		"click_the_add_campaign_button": "Click the {b_add_campaign} button to clear this welcome messsage.",
		"add_a_campaign_connection": "Add a Campaign Connection",
		"root_connection": "Root Connection",
		"your_base_of_operations": "Your base of operations",
		"typically_this_is_your_brand": "Typically, this is your brand name",
		"branch_connection": "Branch connection",
		"delimit_organization_parts_with": "Delimit organization parts with @",
		"required": "Required.",
		"archive": "Archive",
		"campaign_to_archive": "Campaign to archive",
		"if_you_archive_this_campaign": "If you archive this campaign, you will not see it appear in your connections. Any Event attached to the archived campaign will no longer be available for use.",
		"new_event_name": "New Event name:",
		"use_only_at_to_separate": "use only @ to separate name values",
		"campaign_url": "Campaign URL",
		"event_tokens_qr": "Event Tokens QR",
		"copy_qr_url_to": "Copy QR URL to earn or redeem tokens",
		"copied": "Copied",
		"thanks": "Thanks",
		"qrtype": "{qrType}",
		"qrType": {
			"Burn": "Redeem",
			"Earn": "Earn",
			"Campaign": "Campaign"
		}
	},
	"eventcard": {
		"event_details": "Event Details",
		"earn_qr": "Earn QR",
		"click_to_print_event_earn": "Click to print Event Earn QR Code",
		"burn_qr": "Redeem QR",
		"click_to_print_event_burn": "Click to print Event Redeem QR Code",
		"event_name": "Event Name",
		"campaigns_event_name": "Campaign's Event name",
		"event_tokens_earned": "Event Tokens Earned",
		"number_of_tokens_earned": "Number of tokens earned with Event",
		"earn_redirect_url": "Earn Redirect URL",
		"url_after_earning_tokens": "URL after earning tokens",
		"burn_redirect_url": "Redeem Redirect URL",
		"url_to_burn_tokens": "URL to Redeem tokens",
		"event_banner_url": "Event Banner URL",
		"image_to_display_while_earning": "Image to display while EARNING tokens",
		"event_burn_banner_url": "Event Redeem Banner URL",
		"image_to_display_while_burning": "Image to display while REDEEMING tokens",
		"last_update": "Last update:",
		"save": "Save",
		"archive": "Archive",
		"event_to_archive": "Event to archive",
		"if_you_archive_this_event": "If you archive this event, you will not see it appear in your Event list.",
		"archive_event": "Archive Event",
		"adding_event_data": "Adding event data...",
		"defer_earn": "Defer Earn",
		"defer_earn_token_after_redirect": "Defer earn token after redirect",
		"total_earn_limit": "Total Earn Limit",
		"total_tokens_can_be_earned": "Total no. of tokens that can be earned for the event",
		"burn_passcode": "Redeem Passcode",
		"passcode_must_be_4_digits": "Passcode must be 4 digits or blank",
		"leave_blank_for_no_passcode": "Leave blank for no passcode"
	},
	"campaignevent": {
		"copy_qr_url_earn_burn": "Copy QR URL to earn or redeem tokens",
		"event_tokens_qr": "Event Tokens QR",
		"qrtype": "{qrType}",
		"copied": "Copied",
		"thanks": "Thanks",
		"qrType": {
			"Burn": "Redeem",
			"Earn": "Earn",
			"Redirect": "Redirect"
		}
	},
	"view": {
		"tokens_to_burn_for": "Tokens to redeem for",
		"counting_earnings": "Counting earnings...",
		"last_earned": "Last Earned:",
		"burn_all": "Redeem All",
		"burn_some": "Redeem Some",
		"register": "Register",
		"burn_tokens": "Redeem Tokens",
		"burn_reward_tokens": "Redeem Reward Tokens",
		"earn_tokens": "Earn Tokens",
		"show_this_to_the_provider": "Show this to the provider for credit (or cancel if necessary).",
		"you_are_about_to_burn": "You are about to redeem all <strong>{thisTokens}</strong> tokens.",
		"you_have_no_tokens": "You have no tokens to redeem",
		"you_have_available_tokens": "You have <strong>{availableTokens}</strong> available tokens to redeem.",
		"we_are_sorry": "We are sorry. You have no available tokens to redeem yet.",
		"sorry_you_cant_do_that": "Sorry, you can't do that. You need to earn tokens before you can redeem them.",
		"token_burn": "Token Redeem",
		"tokens_to_burn": "Tokens to Redeem",
		"burn_more": "Redeem More",
		"burn_less": "Redeem Less",
		"congratulations_you_burned": "Congratulations. You redeemed {tokens} {midSentence} token{plural} (ID: {id}).",
		"well_done_you_just_earned": "Well done. You just earned {t} {midSentence} token{plural} (ID: {id}).",
		"total_equals": "Total = {tokens}",
		"going_to_token_registration": "Going to token registration...",
		"earn_event_tokens_warning": "Earn Event Tokens - WARNING",
		"sorry_you_already_earned": "Sorry. You already earned your {eventName} tokens.",
		"event": "event",
		"something_went_wrong_15_second": "Something went wrong that caused a 15 second delay.",
		"connectiontype": "{connectionType}",
		"connectionType": {
			"Reward": "Reward",
			"Campaign": "Campaign"
		},
		"sorry_total_earn_limit_reached": "Sorry. The total earn limit for {eventName} has been reached."
	},
	"theme": {
		"preview": "Preview",
		"confirm": "Confirm",
		"confirm_to_proceed": "Confirm to proceed",
		"apply": "Apply",
		"revert": "Revert",
		"default": "Default",
		"apply_settings": "Apply Settings",
		"save_and_apply_theme_settings": "Save and Apply Theme settings?",
		"restore_default": "Restore Default",
		"restore_to_default_tqr_theme": "Restore to Default TQR Theme?"
	}
}